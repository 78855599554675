import { Box, Alert } from '@mui/material';
import React, { useEffect, useState } from "react";
import List from "../components/variables/List"

import "./Variables.css"

const Variables = (props) => {

  const [show_alert, setShowAlert] = useState(false)

  useEffect(() => {props.setScrumbs("Variables de sistema")}, [props])
  
  return (
    <Box className="variables_page">
      <List showAlert={setShowAlert}/>

      { show_alert ? 
          <Box className="alert_container">
            <Alert severity="info">
                Copiado al portapapeles
            </Alert>
          </Box>
      : <></> }
    </Box>
  )
}

export default Variables