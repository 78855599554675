import React, {useState, useEffect} from "react";
import {
    Modal,
    Box,
    TableContainer,
    Table,
    TableRow,
    TableHead,
    TableCell,
    TableBody
} from '@mui/material';
import es from 'javascript-time-ago/locale/es'
import TimeAgo from 'javascript-time-ago'

import AuthHeader from '../../resources/AuthHeader';
const API = process.env.REACT_APP_API_URL

TimeAgo.addDefaultLocale(es)
const timeAgo = new TimeAgo('en-US')

const LogModal = (props) => {

    const [tasks, setTasks] = useState([])

    useEffect(() => {
        fetch(API + "/tasks?test_id=" + props.test_id, 
            {
              method: 'GET',
              headers: AuthHeader()
            }
        )
        .then((response) => {            
          if (response.status === 200) {
              return response.json()
          } else if (response.status === 401) {                
              return []
          }
        })
        .then((data) => {
          if (data) {
            setTasks(data)
            console.log(data)
          } 
          
        })
      }, [props.test_id])

    return (
        <Modal
            open={props.open}
            onClose={() => {props.close(false)}}
        >
            <Box className='test_log_modal_container'>
                <Box className="test_log_modal_title">
                    <h2>Log de ejecución</h2>
                </Box>
                <Box className="test_log_modal_text">
                    {props.log}
                </Box>

                <Box className="test_log_modal_title">
                    <h2>Tareas pendientes</h2>
                </Box>
                <Box className="test_log_modal_text">
                {
                    tasks.length === 0
                    ?
                        <>No hay tareas pendientes</>
                    :
                        <TableContainer sx={{ flex: 1 }}>
                            <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                <TableCell align='center' sx={{width: 50}}>Estado</TableCell>
                                <TableCell align='center' sx={{width: 120}}>Engine</TableCell>
                                <TableCell align='center' sx={{width: 60}}>Tiempo</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    tasks.map((task, index) => {
                                        return (
                                            <TableRow hover={true} height={75} key={index}>
                                                <TableCell align='center'>
                                                    {task["status"]}
                                                </TableCell>
                                                <TableCell align='center'>
                                                    {task["engine"]}                       
                                                </TableCell>
                                                <TableCell align='center'>
                                                    {timeAgo.format(task["timestamp"]*1000)}
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })
                                }
                            </TableBody>
                            </Table>
                        </TableContainer>
                    }
                </Box>

            </Box>        
        </Modal>
    )

}

export default LogModal