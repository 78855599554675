import { Box, LinearProgress } from '@mui/material';
import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';

import ReactCompareImage from 'react-compare-image'

import AuthHeader from '../resources/AuthHeader';
const API = process.env.REACT_APP_API_URL

const Alert = (props) => {

  const { alert_id } = useParams()
  const [state, setState] = useState("loading")
  const [alert, setAlert] = useState()

  useEffect(() => {
    fetch(API + "/alerts/" + alert_id + "/", 
        {
          method: 'GET',
          headers: AuthHeader()
        }
    )
    .then((response) => response.json())
    .then((data) => {
        setAlert(data)
        setState("200")
        props.setScrumbs("Ver alerta")
    })
    .catch((error) => console.log(error))
  }, [alert_id, props])
  
  
  return (

    <Box className="alert_page">
      {
        state === "loading" 
        ?
        <Box sx={{ width: '100%', marginTop: 5 }}>
            <LinearProgress color="secondary" />
        </Box> 
        :
        <Box className="test_alert">
          {
                    alert.type === "screenshot" ? 
                        <ReactCompareImage 
                            leftImage={API + "/image/?id=" + alert.value.old + "_old&no-cache-string=" + Math.random()}
                            rightImage={API + "/image/?id=" + alert.value.new + "_new&no-cache-string=" + Math.random()}
                            leftImageLabel="Antigua"
                            rightImageLabel="Nueva"
                            sliderLineColor="#7b1fa2"
                        />
                    :
                        <Box>
                            <Box className="test_alert_text">
                                <span className="test_alert_text_key">Actualización:</span> {alert.type}
                            </Box>
                            <Box className="test_alert_text">
                                <span className="test_alert_text_key">Valor antiguo:</span> {alert.value.old}
                            </Box>
                            <Box className="test_alert_text">
                                <span className="test_alert_text_key">Valor nuevo:</span> {alert.value.new}
                            </Box>
                        </Box>
                }
        </Box>
      }
    </Box>
  )
}

export default Alert