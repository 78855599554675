import { TextField, Fab, Box, Tooltip, LinearProgress } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import React, { useState, useEffect } from "react";

import NewTest from '../components/tests/NewTest';
import Filters from '../components/tests/Filters';
import GlobalActions from '../components/tests/GlobalActions';
import List from '../components/tests/List';

import FourOOne from '../components/general/FourOOne'

import "./Tests.css"

import AuthHeader from '../resources/AuthHeader';
const API = process.env.REACT_APP_API_URL

const Tests = (props) => {

  const [status, setStatus] = useState("loading")
  const [tests, setTests] = useState([])
  const [tests_status, setTestsStatus] = useState({})

  const [open_new_test_modal, setOpenNewTestModal] = useState(false)

  const [search_query, setSearchQuery] = useState("")
  const [search_words, setSearchWords] = useState("")
  const [search_filters, setSearchFilters] = useState([])

  useEffect(() => {props.setScrumbs("Tests")})

  useEffect(() => {
    loadTest()
    loadStatus()
    const interval_test = setInterval(() => {
      loadTest()
    }, 2000)
    const interval_status = setInterval(() => {
      loadStatus()
    }, 1500)
    return () => {
      clearInterval(interval_test)
      clearInterval(interval_status)
    }
  }, [])

  useEffect(() => {
    processSearchQuery(search_query.toLocaleLowerCase())
  }, [search_query])

  const loadTest = () => {
    fetch(API + "/tests/", 
        {
          method: 'GET',
          headers: AuthHeader()
        }
    )
    .then((response) => {            
      if (response.status === 200) {
          return response.json()
      } else if (response.status === 401) {
          setStatus("401")                
          return null
      }
    })
    .then((data) => {
      if (data) {
        setTests(data)
        setStatus("200")
      }
    })
  }

  const loadStatus = () => {
    fetch(API + "/tests/status/", 
        {
          method: 'GET',
          headers: AuthHeader()
        }
    )
    .then((response) => response.json())
    .then((data) => {
      setTestsStatus(data)
    })

  }

  const scrapTest = (test, index) => {
    let show = true
    // Search words
    show = show && ( search_words === "" || test.name.toLowerCase().includes(search_words) )
    // Filters
    for (let i = 0; i < search_filters.length; i++) {
      let filter = search_filters[i]
      if (filter.key in test) {
        if (filter.operator === "=") {
          if (test[filter.key] !== filter.value)
            show = show && false
        } else if (filter.operator === ">") {
          if (test[filter.key] <= filter.value)
            show = show && false
        } else if (filter.operator === "<") {
          if (test[filter.key] >= filter.value)
            show = show && false
        }
      }
      let status = tests_status[test["_id"]]
      if (filter.key in status) {
        if (filter.operator === "=") {
          if (status[filter.key] !== filter.value)
            show = show && false
        } else if (filter.operator === ">") {
          if (status[filter.key] <= filter.value)
            show = show && false
        } else if (filter.operator === "<") {
          if (status[filter.key] >= filter.value)
            show = show && false
        }
      }
    }
    return show
  }

  const scrap = (test_id, test_name) => {
    let test_ids = []
    if (test_id !== "filtered") {
      test_ids[0] = test_id
    } else {
      for (let i = 0; i < tests.length; i++) {
        if (scrapTest(tests[i]) && tests[i]["engine"] !== "manual" && tests[i]["run_button_enable"])
          test_ids.push(tests[i]["_id"])
      }
    }

    const body = {
      "test_ids": test_ids,
      "engine": props.engine_id
    }
    var headers = AuthHeader()
    headers.append("Content-Type", "application/json");
    fetch(API + "/tasks",
        {
          method: 'POST',
          headers: headers,
          body: JSON.stringify(body)
        }
    )
    .then((response) => response.json())
    .then((data) => {})
  }

  const updateTime = (test_id, test_name) => {
    const body = {
      "id": test_id,
      "status": {
        "status": "ok",
        "last_check": Date.now()/1000,
        "log": "Revisado manualmente"
      }
    }
    var headers = AuthHeader()
    headers.append("Content-Type", "application/json");
    fetch(API + "/test/status/set/",
        {
          method: 'POST',
          headers: headers,
          body: JSON.stringify(body)
        }
    )
    .then((response) => response.json())
    .then((data) => {})
  }

  const processSearchQuery = (query) => {
    let words = ""
    let filters = []

    if (query.includes("/")) {
      let terms = query.split("/")
      for (let i = 0; i < terms.length; i++) {
        if (terms[i].includes(":true") || terms[i].includes(":false")) {
          let splitied_term = terms[i].split(":")
          filters.push({
            "key": splitied_term[0],
            "value": splitied_term[1] === "true" ? true : false,
            "operator": "="
          })
        } else if (terms[i].includes(":")) {
          let splitied_term = terms[i].split(":")
          filters.push({
            "key": splitied_term[0],
            "value": splitied_term[1] ,
            "operator": "="
          })
        } else if (terms[i].includes(">")) {
          let splitied_term = terms[i].split(">")
          filters.push({
            "key": splitied_term[0],
            "value": parseInt(splitied_term[1]),
            "operator": ">"
          })
        } else if (terms[i].includes("<")) {
          let splitied_term = terms[i].split(">")
          filters.push({
            "key": splitied_term[0],
            "value": parseInt(splitied_term[1]),
            "operator": "<"
          })
        } else {
          if (terms[i] !== " ")
            words += terms[i]
        }
      }
    } else {
      words = query
    }

    setSearchWords(words)
    setSearchFilters(filters)    
  }
  
  return (
    <Box className="tests_page">

      <NewTest
        open={open_new_test_modal}
        close={setOpenNewTestModal}
        openTestEditor={(id) => {
          setOpenNewTestModal(false);
          window.location.href = "/test/" + id
        }}
      />

      <Box className='actions_row'>
        <Box className="actions_row_left_container">
          <Box className="search_box_container">
            <TextField
              fullWidth
              id="fullWidth"
              color="secondary"
              size="small"
              variant="standard"
              placeholder="seesaw /alerts>0/"
              value={search_query}
              onChange={(event) => {
                setSearchQuery(event.target.value)
                //(event.target.value)
              }}
            />
          </Box>
          <Filters
            tests={tests}
            tests_status={tests_status}
            search_query={search_query}
            setSearchQuery={setSearchQuery}
          />
        </Box>
        <Box className="actions_row_right_container">
          <GlobalActions scrap={scrap}/>
        </Box>
      </Box>

      { status === "loading" 
      ?
          <Box sx={{ width: '100%', marginTop: 5 }}>
              <LinearProgress color="secondary" />
          </Box> 
      : <></> }
      { status === "401" 
      ?
          <FourOOne/>
      : <></> }
      { status === "200" 
      ?
        <List
          tests={tests}
          tests_status={tests_status}
          search_filters={search_filters}
          search_words={search_words}
          scrap={scrap}
          updateTime={updateTime}
        />
      : <></> }  

      <Box className="fab_container">
        <Tooltip title="Añadir nuevo test">
          <Fab
            color="secondary"
            aria-label="add"
            onClick={() => {
              setOpenNewTestModal(true)
          }}
          >
            <AddIcon/>
          </Fab>
        </Tooltip>        
      </Box>
      
    </Box>
    );
  }
  
  export default Tests;