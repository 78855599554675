import {
    Box,
    TextField,
    FormControl,
    InputLabel,
    MenuItem,
    Select
} from '@mui/material';
import React from "react";

const Definition = (props) => {

    return (
        <Box>
            <Box className="test_editor_form">
                <Box className="test_editor_form_row">
                    <Box className="test_editor_form_input">
                        <TextField
                        id="name"
                        label="Nombre"
                        variant="outlined"
                        color="primary"
                        fullWidth
                        value={props.test.name}
                        onChange={event => {
                            let new_test = {...props.test}
                            new_test.name = event.target.value
                            props.setTest(new_test)
                        }}/>
                    </Box>
                </Box>
                <Box className="test_editor_form_row">
                    <Box className="test_editor_form_input">
                        <FormControl fullWidth>
                        <InputLabel id="type" color="primary">Tipo</InputLabel>
                        <Select
                            labelId = "type"
                            id = "type"
                            value = {props.test.type}
                            label = "Tipo"
                            variant="outlined"
                            color="primary"
                            onChange = {event => {
                                let new_test = {...props.test}
                                new_test.type = event.target.value
                                props.setTest(new_test)
                            }}
                        >
                            <MenuItem value="web">Web</MenuItem>
                            <MenuItem value="android">Android</MenuItem>
                            <MenuItem value="ios">iOS</MenuItem>
                            <MenuItem value="pc">PC</MenuItem>
                        </Select>
                        </FormControl>           
                    </Box>
                    <Box className="test_editor_form_input">
                        <FormControl fullWidth>
                        <InputLabel id="engine" color="primary">Motor</InputLabel>
                        <Select
                            labelId = "engine"
                            id = "engine"
                            value = {props.test.engine}
                            label = "Motor"
                            variant="outlined"
                            color="primary"
                            onChange = {event => {
                                let new_test = {...props.test}
                                new_test.engine = event.target.value
                                props.setTest(new_test)
                            }}
                        >
                            <MenuItem value="chrome">Chrome</MenuItem>
                            <MenuItem value="manual">Manual</MenuItem>
                        </Select>
                        </FormControl>           
                    </Box>
                    <Box className="test_editor_form_input">
                        <FormControl fullWidth>
                        <InputLabel id="ui_depends_on_os_version" color="primary">UI varía según ver. SO</InputLabel>
                        <Select
                            labelId = "ui_depends_on_os_version"
                            id = "ui_depends_on_os_version"
                            value = {props.test.ui_depends_on_os_version ? "yes" : "no"}
                            label = "UI varia segun ver. SO"
                            variant="outlined"
                            color="primary"
                            onChange = {event => {
                                let new_test = {...props.test}
                                new_test.ui_depends_on_os_version = event.target.value === "yes"
                                props.setTest(new_test)
                            }}
                        >
                            <MenuItem value="no">No</MenuItem>
                            <MenuItem value="yes">Sí</MenuItem>
                        </Select>
                        </FormControl>           
                    </Box>
                    <Box className="test_editor_form_input">
                        <FormControl fullWidth>
                        <InputLabel id="run_in_bulk" color="primary">Correr en automático</InputLabel>
                        <Select
                            labelId = "run_in_bulk"
                            id = "run_in_bulk"
                            value = {props.test.run_in_bulk ? "yes" : "no"}
                            label = "Correr en automático"
                            variant="outlined"
                            color="primary"
                            onChange = {event => {
                                let new_test = {...props.test}
                                new_test.run_in_bulk = event.target.value === "yes"
                                props.setTest(new_test)
                            }}
                        >
                            <MenuItem value="no">No</MenuItem>
                            <MenuItem value="yes">Sí</MenuItem>
                        </Select>
                        </FormControl>           
                    </Box>
                    <Box className="test_editor_form_input">
                        <FormControl fullWidth>
                        <InputLabel id="adblocker" color="primary">Adblocker</InputLabel>
                        <Select
                            labelId = "adblocker"
                            id = "adblocker"
                            value = {props.test.adblocker}
                            label = "adblocker"
                            variant="outlined"
                            color="primary"
                            onChange = {event => {
                                let new_test = {...props.test}
                                new_test.adblocker = event.target.value
                                props.setTest(new_test)
                            }}
                        >
                            <MenuItem value="no">No</MenuItem>
                            <MenuItem value="adblock">AdBlock</MenuItem>
                        </Select>
                        </FormControl>           
                    </Box>
                    <Box className="test_editor_form_input">
                        <FormControl fullWidth>
                        <InputLabel id="user_interaction" color="primary">Interacción usuarie</InputLabel>
                        <Select
                            labelId = "user_interaction"
                            id = "user_interaction"
                            value = {props.test.user_interaction ? "yes" : "no"}
                            label = "interaccion_usuarie"
                            variant="outlined"
                            color="primary"
                            onChange = {event => {
                                let new_test = {...props.test}
                                new_test.user_interaction = event.target.value === "yes"
                                props.setTest(new_test)
                            }}
                        >
                            <MenuItem value="no">No</MenuItem>
                            <MenuItem value="yes">Sí</MenuItem>
                        </Select>
                        </FormControl>           
                    </Box>
                    <Box className="test_editor_form_input">
                        <FormControl fullWidth>
                        <InputLabel id="run_button_enable" color="primary">Botón ejecutar</InputLabel>
                        <Select
                            labelId = "run_button_enable"
                            id = "run_button_enable"
                            value = {props.test.run_button_enable ? "true" : "false"}
                            label = "boton_ejecutar"
                            variant="outlined"
                            color="primary"
                            onChange = {event => {
                                let new_test = {...props.test}
                                new_test.run_button_enable = event.target.value === "true"
                                props.setTest(new_test)
                            }}
                        >
                            <MenuItem value="false">No clicable</MenuItem>
                            <MenuItem value="true">Clicable</MenuItem>
                        </Select>
                        </FormControl>           
                    </Box>
                </Box>
                <Box className="test_editor_form_row">
                    <Box className="test_editor_form_input">
                        <TextField
                            id="pretest"
                            label="Pre test"
                            variant="outlined"
                            color="primary"
                            fullWidth
                            value={props.test.pre_test}
                            onChange={event => {
                                let new_test = {...props.test}
                                new_test.pre_test = event.target.value
                                props.setTest(new_test)
                            }}
                        />
                    </Box>
                </Box>
                <Box className="test_editor_form_row">
                    <Box className="test_editor_form_input">
                        <TextField
                            id="notes"
                            label="Notas"
                            variant="outlined"
                            color="primary"
                            multiline
                            minRows={5}
                            fullWidth
                            value={props.test.notes}
                            onChange={event => {
                                let new_test = {...props.test}
                                new_test.notes = event.target.value
                                props.setTest(new_test)
                            }}
                            />
                    </Box>
                </Box>

            </Box>                       

        </Box>
    )
}

export default Definition