import { Box } from '@mui/material';
import React, { useEffect } from "react";
import { useParams } from 'react-router-dom';

import List from '../components/history/List';

import "./History.css"

import AuthHeader from '../resources/AuthHeader';
const API = process.env.REACT_APP_API_URL

const History = (props) => {

  const { test_id } = useParams()

  useEffect(() => {
    fetch(API + "/test/?id=" + test_id, 
          {
            method: 'GET',
            headers: AuthHeader()
          }
    )
    .then((response) => {            
      if (response.status === 200) {
          return response.json()
      } else if (response.status === 401) {  
          return null
      }
    })
    .then((data) => {
        if (data) {
          props.setScrumbs("Historia > " + data.name)
        } else {
          props.setScrumbs("Historia")
        }
        
    })
  }, [test_id, props])

  return (
    <Box className="history_page">
      <List test_id={test_id} />
    </Box>
  )
}

export default History