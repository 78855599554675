import { Box } from '@mui/material';

const ActivitiyViewer = (props) => {

  if (props.activity)
    return (
      <Box className="activity_container" >
        <iframe className="activity_viewer" title="visor de actividades" src={props.activity.web}></iframe>       
      </Box>
    )
  else
      return (
        <Box className="activity_container" >
        <Box className="activity_header">
          <Box className="activity_header_title">
            <h2>Selecciona una actividad</h2>
          </Box>
        </Box>    
      </Box>
      )
}

export default ActivitiyViewer