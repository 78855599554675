import { Button, Box, } from '@mui/material';
import React from "react";

const Move = (props) => {

    return (
        <Box className="app_editor_move_step_buttons_row">
            <Button
                variant="outlined"
                color="primary"
                disabled={props.index === props.steps-1 ? true : false}
                style={{marginRight: 10}} onClick={() => {props.move(props.index, props.index+1)}}
            >
                Bajar
            </Button>
            <Button
                variant="outlined"
                color="primary"
                disabled={props.index === 0 ? true : false}
                style={{marginRight: 10}}
                onClick={() => {props.move(props.index, props.index-1)}}
            >
                Subir
            </Button>
            <Button
                variant="text"
                color="primary"
                style={{marginRight: 30}} onClick={() => {
                    if (window.confirm("Seguro que quieres eliminar")) {
                        props.delete(props.index)
                    }
                }}
            >
                Eliminar
            </Button>
        </Box>
    )
}

export default Move