import {
    Box,
    FormControl,
    InputLabel,
    Select,
    MenuItem
} from '@mui/material';
import React from "react";

const Campaign = (props) => {

    return (
        <Box>
            <Box className="test_editor_form">
                <Box className="test_editor_form_row">
                    <Box className="test_editor_form_input">
                        <FormControl fullWidth>
                        <InputLabel id="campaign" color="primary">Campaña</InputLabel>
                        <Select
                            labelId = "campaign"
                            id = "campaign"
                            value = {props.test.campaign}
                            label = "campaign"
                            variant="outlined"
                            color="primary"
                            onChange = {event => {
                                let new_test = {...props.test}
                                new_test.campaign = event.target.value
                                props.setTest(new_test)
                            }}
                        >
                            <MenuItem value="no">No</MenuItem>
                            <MenuItem value="daily">Daily</MenuItem>
                            <MenuItem value="fve">FVE</MenuItem>
                            <MenuItem value="training">Formaciones</MenuItem>
                        </Select>
                        </FormControl>           
                    </Box>
                </Box>
            </Box>                       
        </Box>
    )
}

export default Campaign