import {
  Box,
} from '@mui/material';
import React from "react";

const FourOOne = (props) => {

  return (
    <Box sx={{ width: '100%', marginTop: 5 }}>
        <h1>No tienes permiso para ver los datos pedidos</h1>
    </Box>
  )
}

export default FourOOne