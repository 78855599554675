const AuthHeader = () => {
  // Get token from cookie
  var cookies = document.cookie.split("; ")
  var token = ""
  for (var i = 0; i < cookies.length; i++)
    if (cookies[i].includes("token"))
      token = cookies[i].split("=")[1]
  // Set the requests
  var headers = new Headers();
  headers.append("Authorization", "Bearer " + token);

  return headers
}

export default AuthHeader