import {
    Box,
    TextField,
    IconButton,
    Tooltip,
    LinearProgress
} from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import React, {useEffect, useState} from "react";

import FourOOne from '../general/FourOOne';

import AuthHeader from '../../resources/AuthHeader';
const API = process.env.REACT_APP_API_URL

const Activities = (props) => {

    const [status, setStatus] = useState("loading")
    const [activities, setActivities] = useState([]);
    const [search, setSearch] = useState("")
    const [search_activity, setSearchActivity] = useState(null)

    useEffect(() => {
        fetch(API + "/activities/?test_id=" + props.test._id, 
            {
            method: 'GET',
            headers: AuthHeader()
            }
        )
        .then((response) => {            
            if (response.status === 200) {
                return response.json()
            } else if (response.status === 404) {    
                console.log("404")    
                return null
            } else if (response.status === 401) {             
                return null
            }
        })
        .then((data) => {
            if (data) {
                setActivities(data)
                setStatus("200")
            }
        })
    }, [props.test._id])

    const searchActivity = (id, load_directly) => {
        if (id.length === 0) {
            setSearchActivity(null)
            return false
        }
        fetch(API + "/activities/" + id + "/", 
            {
            method: 'GET',
            headers: AuthHeader()
            }
        )
        .then((response) => {            
            if (response.status === 200) {
                return response.json()
            } else if (response.status === 404) {    
                console.log("404")    
                setSearchActivity(null)
                return null
            } else if (response.status === 401) {             
                return null
            }
        })
        .then((data) => {
            if (data) {
                if (load_directly) {
                    let _activities = [...activities]
                    _activities.push(data)
                    setActivities(_activities)
                    setStatus("200")
                } else {
                    setSearchActivity(data)
                }
            }
        })
    }

    const addActivity = (activitiy) => {
        let _activities = [...activities]
        _activities.push(activitiy)
        setActivities(_activities)
        let _test = {...props.test}
        _test.activities.push(activitiy._id)
        props.setTest(_test)
        setSearchActivity(null)
        setSearch("")
    }

    const deleteActivity = (index) => {
        let _activities = [...activities]
        delete _activities[index]
        setActivities(_activities)
        let _test = {...props.test}
        _test.activities.splice(index, 1)
        props.setTest(_test)
    }

    return (
        <Box>
            <Box className="test_editor_form">
                <Box className="test_editor_form_row">
                    <Box className="test_editor_form_input">
                        <TextField
                            id="serach"
                            label="Buscar"
                            variant="outlined"
                            color="primary"
                            fullWidth
                            value={search}
                            onChange={event => {
                                setSearch(event.target.value)
                                searchActivity(event.target.value, false)
                            }}
                        />
                    </Box>   
                    
                </Box>
            </Box>       
            {
                search_activity ? 
                    <Box className="test_editor_activity test_editor_activity_search">
                        <Box className="test_editor_activity_title">
                            {search_activity._id} - {search_activity.name}
                        </Box>
                        <Box className="test_editor_activity_buttons">
                            <Tooltip title="Abrir actividad"> 
                                <IconButton onClick={() => {window.open(search_activity.web, '_blank').focus()}}>
                                    <OpenInNewIcon style={{height: 20}}/>
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Añadir actividad"> 
                                <IconButton onClick={() => {addActivity(search_activity)}}>
                                    <AddCircleIcon style={{height: 20}}/>
                                </IconButton>
                            </Tooltip>
                        </Box>
                    </Box>
                : <></>
            }                
            <Box className="test_editor_activities_container">
                { status === "loading" 
                    ?
                        <Box sx={{ width: '100%', marginTop: 5 }}>
                            <LinearProgress color="secondary" />
                        </Box> 
                    : <></> }
                    { status === "401" 
                    ?
                        <FourOOne/>
                    : <></> }
                    { status === "200" 
                    ?
                        activities.map((activitiy, index) => {
                            return (
                                <Box className="test_editor_activity" key={activitiy._id}>
                                    <Box className="test_editor_activity_title">
                                        {activitiy._id} - {activitiy.name}
                                    </Box>
                                    <Box className="test_editor_activity_buttons">
                                        <Tooltip title="Abrir actividad"> 
                                            <IconButton onClick={() => {window.open(activitiy.web, '_blank').focus()}}>
                                                <OpenInNewIcon style={{height: 20}}/>
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Eliminar actividad"> 
                                            <IconButton onClick={() => {deleteActivity(index)}}>
                                                <DeleteIcon style={{height: 20}}/>
                                            </IconButton>
                                        </Tooltip>
                                    </Box>
                                </Box>
                            )
                        })
                    : <></>} 
            </Box>
        </Box>
    )
}

export default Activities