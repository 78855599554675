import { Box, Tooltip, Fab } from '@mui/material';
import React, { useState, useEffect } from "react";
import AddIcon from '@mui/icons-material/Add';

import List from "../components/users/List"
import NewUser from '../components/users/NewUser';

import "./Users.css"

const Users = (props) => {

  const [open_new_user_modal, setOpenNewUserModal] = useState(false)

  useEffect(() => {props.setScrumbs("Usuaries")}, [props])
  
  return (
    <Box className="users_page">

      <NewUser
        open={open_new_user_modal}
        close={setOpenNewUserModal}
      />

      <List/>

      <Box className="fab_container">
        <Tooltip title="Añadir nuevo test">
          <Fab
            color="secondary"
            aria-label="add"
            onClick={() => {
              setOpenNewUserModal(true)
          }}
          >
            <AddIcon/>
          </Fab>
        </Tooltip>        
      </Box>
    </Box>
  )
}

export default Users